import React, { useEffect, useState } from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaX } from "react-icons/fa6";

const Layout = ({ children }) => {
  const [customerList, setCustomerList] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = (index) => {
    setShowModal(index === showModal ? null : index);
  };
  const clientInformation = useSelector(
    (state) => state.newOrderAdminSlice.clientInformation
  );
  const orderDetails = useSelector(
    (state) => state.newOrderAdminSlice.orderDetails
  );
  const funeralDetails = useSelector(
    (state) => state.newOrderAdminSlice.funeralDetails
  );
  const selectedVideo = useSelector(
    (state) => state.newOrderAdminSlice.selectedVideo
  );
  const selectedImg = useSelector(
    (state) => state.newOrderAdminSlice.selectedImg
  );
  const selectedMusic = useSelector(
    (state) => state.newOrderAdminSlice.selectedMusic
  );
  const customerInfo = useSelector((state) => state.customerInfoSlice.customer);
  const navigate = useNavigate();
  const token = localStorage.getItem("adminToken");

  // const reqBody = {
  //   businessName: customerDetails?.bussinessName,
  //   contactName: customerDetails?.firstName + " " + customerDetails?.lastName,
  //   email: customerDetails?.email,
  //   phone: customerDetails?.phone,
  //   address: customerDetails?.address,
  //   city: customerDetails?.city,
  //   state: customerDetails?.state,
  //   postalCode: Number(customerDetails?.postalCode),
  //   requestedDate: orderDetails?.requestedDate || null,
  //   styleOption: orderDetails?.styleOption,
  //   videoType: orderDetails?.videoType,
  //   productionType: orderDetails?.productionType,
  //   subjectName: funeralDetails?.subjectName,
  //   orderCreationDate: orderDetails?.orderCreationDate || null,
  //   dateOfBirth: funeralDetails?.dateOfBirth || null,
  //   dateOfDeath: funeralDetails?.dateOfDeath || null,
  //   funeralDate: funeralDetails?.funeralDate || null,
  //   funeralTime: funeralDetails?.funeralTime || null,
  //   coverPhotos: selectedImg,
  //   scenery: selectedVideo,
  //   music: selectedMusic,
  //   // customer_id: Number(localStorage.getItem("adminId")),
  //   customer_id: Number(customerDetails?.id),
  //   pricingObj: {
  //     totalPricing: 0,
  //     musicPricing: 0,
  //     uploadPricing: 0,
  //     coverImgPricing: 0,
  //     sceneryPricing: 0,
  //     uploadVideoPricing: 0,
  //   },
  // };

  // const handleSubmit = () => {
  //   console.log(customerDetails, "customerDetails");

  //   axios({
  //     method: "POST",
  //     url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/create-order",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //     data: reqBody,
  //   })
  //     .then((res) => {
  //       navigate(`/admin/new-order/${res?.data?.Data?.id}`);
  //       setShowModal(false);
  //     })
  //     .catch((err) => {});
  // };

  // const handleChange = (e) => {
  //   setCustomerName(e.target.value);
  // };

  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const fetchAllCustomers = () => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/get-all-customers",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // setCustomerList(res?.data?.Data?.rows);
        setFilteredCustomerList(res?.data?.Data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (showModal === "selectCustomer") {
      fetchAllCustomers();
    }
  }, [showModal]);

  const handleFilterList = () => {
    const filteredList = customerList.filter((v) => {
      return (
        customerName?.toLowerCase() === v?.firstName?.toLowerCase() ||
        customerName?.toLowerCase() === v?.lastName?.toLowerCase()
      );
    });
    setFilteredCustomerList(filteredList);
  };

  useEffect(() => {
    if (customerName === "") {
      setFilteredCustomerList(customerList);
    }
  }, [customerName]);

  // const handleFetchCustomer = (id) => {

  //   return new Promise((resolve, reject) => {
  //     axios({
  //       method: "GET",
  //       url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/get-customer-by-id/${id}`,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((res) => {
  //         let data = res?.data?.Data;
  //         setCustomerDetails({
  //           id: data?.id,
  //           firstName: data.firstName,
  //           lastName: data.lastName,
  //           email: data?.email,
  //           country: data?.country,
  //           address: data?.address,
  //           phone: data?.phone,
  //           businessName: data?.bussinessName,
  //         });
  //         resolve(data);
  //       })
  //       .then(() => {
  //         handleSubmit();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(err);
  //       });
  //   });
  // };

  const handleFetchCustomer = (id) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/get-customer-by-id/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const data = res?.data?.Data;

          // Resolve the promise with the fetched data
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err); // Reject the promise with the error
        });
    })
      .then((data) => {
        // Call handleSubmit with the fetched data
        handleSubmit(data);
      })
      .catch((err) => {
        console.error("Error in handleFetchCustomer:", err);
      });
  };

  const handleSubmit = async (customerData) => {
    const reqBody = {
      businessName: customerData?.bussinessName,
      contactName: customerData?.firstName + " " + customerData?.lastName,
      email: customerData?.email,
      phone: customerData?.phone,
      address: customerData?.address,
      city: customerData?.city,
      state: customerData?.state,
      postalCode: Number(customerData?.postalCode),
      requestedDate: orderDetails?.requestedDate || null,
      styleOption: orderDetails?.styleOption,
      videoType: orderDetails?.videoType,
      productionType: orderDetails?.productionType,
      subjectName: funeralDetails?.subjectName,
      orderCreationDate: orderDetails?.orderCreationDate || null,
      dateOfBirth: funeralDetails?.dateOfBirth || null,
      dateOfDeath: funeralDetails?.dateOfDeath || null,
      funeralDate: funeralDetails?.funeralDate || null,
      funeralTime: funeralDetails?.funeralTime || null,
      coverPhotos: selectedImg,
      scenery: selectedVideo,
      music: selectedMusic,
      customer_id: Number(customerData?.id),
      pricingObj: {
        totalPricing: 0,
        musicPricing: 0,
        uploadPricing: 0,
        coverImgPricing: 0,
        sceneryPricing: 0,
        uploadVideoPricing: 0,
      },
    };

    console.log(customerData, "customerData in handleSubmit");

    await axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/create-order",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: reqBody,
    })
      .then((res) => {
        navigate(`/admin/new-order/${res?.data?.Data?.id}`);
        setShowModal(false);
      })
      .catch((err) => {
        console.error("Error in handleSubmit:", err);
      });
  };

  const [query, setQuery] = useState("");

  const handleKeyDown = async (e) => {
    // Check if the key pressed is not empty (optional)
    if (e.key !== "Backspace" && e.key !== "Shift") {
      const newQuery = e.target.value + e.key;

      try {
        const response = await axios.get(
          `https://funeral-api.hitoritech.co.uk/api/v1/admin/get-customer-search-result/${newQuery}`
        );
        console.log(response?.data);
        setCustomerList(response?.data);
      } catch (error) {
        console.error("Error fetching results:", error);
      }
    }
  };
  const handleChange = (e) => {
    setQuery(e.target.value); // Update query for UI/UX feedback
  };
  return (
    <div className="w-full flex  relative max-h-[100vh] overflow-y-hidden">
      <ToastContainer />
      <div className="w-[17%] hidden xl:block border-r border-gray-200">
        <Sidebar
          showModal={showModal}
          setShowModal={setShowModal}
          handleShowModal={handleShowModal}
        />
      </div>
      <div className="w-full xl:w-[83%]   px-[10px] h-screen overflow-y-scroll hide-scrollbar  flex flex-col  ">
        <Header
          showModal={showModal}
          setShowModal={setShowModal}
          handleShowModal={handleShowModal}
        />

        <div className="bg-[#b2b7bb] py-[20px] px-[15px] w-full h-full rounded-[20px]">
          <div className="w-full  h-full overflow-y-scroll bg-white rounded-[20px] hide-scrollbar">
            {children}
          </div>
        </div>
      </div>
      {showModal === "newOrder" ? (
        <div className="w-full h-full p-[30px] fixed bg-black top-0 left-0 z-[99] bg-opacity-[0.5] flex flex-col justify-center items-center">
          <div className="w-full text-center sm:w-[80%] xl:w-[50%] h-[300px] justify-center p-[30px] flex bg-white rounded-[7px] flex-col items-center gap-[20px]">
            <h2 className="font-semibold text-[24px]">
              Do you want to create new order?
            </h2>
            <div className="w-full flex gap-[12px] justify-center items-center">
              <button
                onClick={() => setShowModal(false)}
                className="px-[20px] font-semibold py-[12px] bg-red-500 text-white  rounded-[7px]"
              >
                No
              </button>
              <button
                // onClick={() => handleSubmit()}
                onClick={() => setShowModal("selectCustomer")}
                className="px-[20px] font-semibold py-[12px] bg-green-500 text-white  rounded-[7px]"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {showModal === "selectCustomer" ? (
        <div className="w-full h-full p-[30px] fixed bg-black top-0 left-0 z-[99] bg-opacity-[0.5] flex flex-col justify-center items-center">
          <div className="w-full text-center sm:w-[80%] xl:w-[50%]  justify-center p-[30px] flex bg-white rounded-[7px] flex-col items-center gap-[20px]">
            <div className="w-full flex items-center justify-between">
              <h2 className="font-semibold text-[24px]">Select Customer</h2>
              <FaX
                className="cursor-pointer"
                onClick={() => handleShowModal(false)}
              />
            </div>
            <div className="w-full flex items-center gap-[7px]">
              <input
                type="text"
                placeholder="Search..."
                value={query}
                name="query"
                id="query"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className="w-full p-[7px] border-green-500 border outline-none rounded-[7px]"
              />
              <button
                onClick={() => {
                  handleFilterList();
                }}
                className="px-[20px] py-[10px] rounded-[7px] text-white bg-green-500"
              >
                Search
              </button>
            </div>
            {customerList?.length !== 0 ? (
              <div className="w-full flex h-[300px] overflow-y-scroll hide-scrollbar flex-col ">
                {customerList?.map((item) => {
                  return (
                    <div
                      key={item?.id}
                      className="w-full flex border-b py-[15px] border-black items-center justify-between"
                    >
                      <p>
                        {item?.firstName} {item?.lastName}
                      </p>
                      <button
                        onClick={() => handleFetchCustomer(item?.id)}
                        className="px-[20px] py-[10px] rounded-[7px] text-white bg-green-500"
                      >
                        Select
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-center font-semibold">No Customer Found!</p>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Layout;
